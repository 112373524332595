import React, { Component, useState } from 'react';
import logo from './logo.svg';
import './App.css';

function App(){
  let [field1,setField1] = useState('')
  let [field2,setField2] = useState('')
  let [field3,setField3] = useState('')
  let [field4,setField4] = useState('')
  let [field5,setField5] = useState('')
  let [submitted,setSubmitted] = useState(false)
  function submit(){
    let fields = {
      field1,
      field2,
      field3,
      field4,
      field5,
      authToken: 'A12D67SAE6A5CB6BDC123A590DDAB'
    }
    fetch('https://us-central1-pdfmang.cloudfunctions.net/jsonapi', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(fields)
    })
      .then(res=>setSubmitted(true))
      
    console.log(fields)
  }
  function clearFields(){
    setField1('')
    setField2('')
    setField3('')
    setField4('')
    setField5('')
    setSubmitted(false)
  }
  return(
    <div id="main">
      {!submitted&&
      <form onSubmit={(ev)=>{
        ev.preventDefault()
        submit()
      }}>
        <div className='form-field'><label>Field 1:&nbsp;</label> <input value={field1} onChange={(ev)=>setField1(ev.target.value)}/></div>
        <div className='form-field'>Field 2:&nbsp;<input value={field2} onChange={(ev)=>setField2(ev.target.value)}/></div>
        <div className='form-field'>Field 3:&nbsp;<input value={field3} onChange={(ev)=>setField3(ev.target.value)}/></div>
        <div className='form-field'>Field 4:&nbsp;<input value={field4} onChange={(ev)=>setField4(ev.target.value)}/></div>
        <div className='form-field'>Field 5:&nbsp;<input value={field5} onChange={(ev)=>setField5(ev.target.value)}/></div>
        <div className="submit-container"><button id="submit">Submit</button></div>
      </form>}
      {submitted&&
        <div className='submitted'>
          <p>JSON data posted. check at <a target='_blank' rel='noopener noreferrer' href='https://requestbin.fullcontact.com/16qycjz1?inspect'>https://requestbin.fullcontact.com/1lqwhye1?inspect</a></p> 
          <p className='submit-another'><button onClick={(ev)=>{
            clearFields()
            ev.preventDefault()
          }}>Fill out a new form</button></p>
        </div>
      }
    <div className="json-data">
      <h5>JSON Preview: </h5>
      <pre>
        {`
  {
    field1:"${field1}",
    field2:"${field2}",
    field3:"${field3}",
    field4:"${field4}",
    field5:"${field5}",
    authToken: 'A12D67SAE6A5CB6BDC123A590DDAB'
  }`
        }
      </pre>
    </div>
  </div>
  )
}

export default App;
